import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FleetService} from '../../../../shared/services/fleet.service';
import {CalculationService} from '../../../../shared/services/calculation.service';
import {HelperService} from '../../../../shared/services/helper.service';
import {ICalculation} from '../../../../shared/interfaces/calculation.interface';
import {CalculationRestService} from '../../../../shared/services/rest-services/calculation-rest.service';

@Component({
  selector: 'app-oe-article-calculation',
  templateUrl: './oe-article-calculation.component.html'
})
export class OeArticleCalculationComponent implements OnInit, OnDestroy {
  public oeArticleCalculations: ICalculation[] = [];
  public oeArticleCalculationSubscription: Subscription;
  public closeResult: string;
  public itemsMoved = false;


  constructor(private modalService: NgbModal,
              public calculationService: CalculationService,
              private calculationRestService: CalculationRestService,
              public helperService: HelperService,
              public fleetService: FleetService) {
  }

  ngOnInit() {
    this.oeArticleCalculationSubscription = this.calculationService.calculations.subscribe(
      (data) => {
        if (data) {
          this.oeArticleCalculations = data;
          this.oeArticleCalculations.sort((a, b) => {
            return a.sortNo - b.sortNo;
          });
        } else {
          this.oeArticleCalculations = [];
        }
      });
  }

  ngOnDestroy() {
    this.oeArticleCalculationSubscription.unsubscribe();
  }

  public csvDownload(): void {
    this.calculationRestService.getSCVDownload(this.fleetService.currentFleetId, 'OE_PART')
      .subscribe((response: ArrayBuffer) => {
        const filename = 'export.csv';
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response]));
        link.setAttribute('download', filename);
        link.click();
      });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.oeArticleCalculations, event.previousIndex, event.currentIndex);
    for (let i = 0; i < this.oeArticleCalculations.length; i++) {
      this.oeArticleCalculations[i].sortNo = i + 2;
    }
    this.itemsMoved = true;
  }

  deleteCalculation(id: number) {
    this.calculationRestService.deleteCalculation(id).subscribe(data => {
      this.calculationService.getCalculationsByFleetAndType(this.fleetService.fleetId.value, 'OE_PART');
      this.calculationService.resetCalculationIds();
    });
  }

  public saveSorting() {
    const mappedCalculations = this.oeArticleCalculations.map(calc => {
      return {id: calc.id, sortNo: calc.sortNo};
    });
    this.calculationRestService.sortCalculation(this.fleetService.fleetId.value, mappedCalculations).subscribe();
    this.itemsMoved = false;
  }


  public open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, () => {
      this.closeResult = `Dismissed`;
    });
  }

}
