import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MaterialCodesService} from '../../../shared/services/material-codes.service';
import {HelperService} from '../../../shared/services/helper.service';
import {FleetService} from '../../../shared/services/fleet.service';
import {DataService} from '../../../shared/services/data.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MaterialCodesRestService} from '../../../shared/services/rest-services/material-codes-rest.service';
import 'lodash';
import {Router} from '@angular/router';

declare var _: any;


@Component({
  selector: 'app-material-codes-view',
  templateUrl: './material-codes-view.component.html'
})
export class MaterialCodesViewComponent implements OnInit, OnDestroy {
  public materialCodes: [] = [];
  public showTab: string = 'list-material-codes';
  public Subscriptions: Subscription [] = [];
  public closeResult: string;
  public deletableMC: string;
  public fleetId: number;


  constructor(private modalService: NgbModal,
              private router: Router,
              private materialCodesService: MaterialCodesService,
              private materialCodesRestService: MaterialCodesRestService,
              public fleetService: FleetService,
              public dataService: DataService,
              public helperService: HelperService) {
  }

  ngOnInit() {
    this.dataService.getTree();
    this.Subscriptions.push(this.fleetService.fleetId.subscribe(id => this.fleetId = id));
    this.Subscriptions.push(this.materialCodesService.materialCodes.subscribe(
      (data) => {
        if (data) {
          const grouped = _.groupBy(data, mc => mc.materialCode);
          this.materialCodes = _.toArray(grouped);
        } else {
          this.materialCodes = [];
        }
      }));
  }

  ngOnDestroy() {
    if (this.Subscriptions) {
      this.Subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
    this.fleetService.currentSubmenu.next(null);
  }

  public csvDownload(): void {
    this.materialCodesRestService.getSCVDownloadMaterialCodes(this.fleetService.currentFleetId)
      .subscribe((response: ArrayBuffer) => {
        const filename = 'export.csv';
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response]));
        link.setAttribute('download', filename);
        link.click();
      });
  }

  public open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, () => {
      this.closeResult = `Dismissed`;
    });
  }

  public deleteMaterialCode() {
    this.materialCodesRestService.deleteMaterialCode(this.deletableMC, this.fleetId).subscribe(() => {
      const currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
      });
    });
  }

}
